import { IOptionType, ProjectDialog } from '@zz2/zz2-ui';
import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';
import { OptionType } from '../../@types/model/optionType';
import { IUser } from '../../@types/model/right/user/user';
import { useAppSelector } from '../../@types/redux';
import EnvHelper from '../../service/helper/envHelper';
import { VERSION } from '../../version';
import FreshServiceTicketForm from './FreshServiceTicketEditForm';
import { useGetUsers } from '../../hooks/query/user/userQueries';
import { useHandleFreshServiceTicketUpsert } from '../../hooks/query/freshService/freshServiceQueries';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTickerFormValues';

interface IFreshServiceTicketEditDialogProps {
    isOpen : boolean;
    onDismiss : () => void;
    description : string;
}

const FreshServiceTicketCreationDialog = (props : IFreshServiceTicketEditDialogProps) : React.ReactElement => {
    const location = useLocation();
    const currentUser = useAppSelector<IUser | null>(x => x.auth.session?.user ?? null);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isUpsertingFreshServiceTicket, mutate: upsertFreshServiceTicket, isSuccess: isUpsertSuccessful } = useHandleFreshServiceTicketUpsert();
    const { isLoading: isLoadingUsers, data: users } = useGetUsers();

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        props.onDismiss();        
    }, [isUpsertSuccessful]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Handler Methods
     * ===============================================================================================================*/   

    const onSubmit = (values : IFreshServiceTicketFormValues) : void => {
        const upsert = FreshServiceTicketModelHelper.createUpsert(values, users);
        
        upsertFreshServiceTicket(upsert);
    };

    const userOptions = useMemo<Array<IOptionType>>(() => {
        return users?.map(OptionType.fromUser) ?? [];
    }, [users]);

    const getInitialFormValues = () : IFreshServiceTicketFormValues => {
        const systemName = EnvHelper.getEnvName();

        return FreshServiceTicketModelHelper.createFormValues(
            systemName, 
            VERSION.version, 
            location.pathname, 
            currentUser?.name ?? '',
            currentUser?.email ?? '',
            props.description
        );
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/     

    const isLoading = useMemo<boolean>(() => {
        return isLoadingUsers
        || isUpsertingFreshServiceTicket;
    }, [
        isLoadingUsers,
        isUpsertingFreshServiceTicket
    ]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/    

    return (
        <ProjectDialog
            title={'ZZ2 Fresh Service Support Ticket'}
            isLoadingCircular={isLoading}
            isOpen={props.isOpen}
            fullWidth
            maxWidth={'sm'}
            onClose={props.onDismiss}>
            <FreshServiceTicketForm initialValues={getInitialFormValues()} onSubmit={onSubmit} onCancel={props.onDismiss} userOptions={userOptions} />
        </ProjectDialog>
    );
};


export default FreshServiceTicketCreationDialog;