import { IBaseModel, IOptionType } from '@zz2/zz2-ui';
import { IBatch } from './batch/batch';
import { IUser } from './right/user/user';

export class OptionType {

    public static fromDataModel = function<T extends IBaseModel>(dataModel : T) : IOptionType {
        let labelNameOrDescription = '';

        if ('code' in dataModel && typeof dataModel.code === 'string' &&
                'name' in dataModel && typeof dataModel.name === 'string') {

            return {
                label: `${dataModel.code} - ${dataModel.name}`,
                value: dataModel.id,
            };
        }

        if ('code' in dataModel && typeof dataModel.code === 'string' &&
            'description' in dataModel && typeof dataModel.description === 'string') {

            return {
                label: `(${dataModel.code}) - (${dataModel.description})`,
                value: dataModel.id,
            };
        }
        
        if ('code' in dataModel && typeof dataModel.code === 'string') {
            labelNameOrDescription += `${dataModel.code} `;
        }

        if ('name' in dataModel && typeof dataModel.name === 'string') {
            labelNameOrDescription += `${dataModel.name} `;
        }

        if ('description' in dataModel && typeof dataModel.description === 'string') {
            labelNameOrDescription += `${dataModel.description} `;
        }

        return {
            label: `${labelNameOrDescription}`,
            value: dataModel.id,
        };
    }

    public static fromDataModelDisplayCode = function<T extends IBaseModel>(dataModel : T) : IOptionType {
        let code = '';

        if ('code' in dataModel && typeof dataModel.code === 'string') {
            code += `${dataModel.code} `;
        }

        return {
            label: code,
            value: dataModel.id,
        };
    }

    public static fromDataModelDisplayName = function<T extends IBaseModel>(dataModel : T) : IOptionType {
        let name = '';

        if ('name' in dataModel && typeof dataModel.name === 'string') {
            name += `${dataModel.name} `;
        }

        return {
            label: name,
            value: dataModel.id,
        };
    }

    public static fromDataModelDisplayDescription = function<T extends IBaseModel>(dataModel : T) : IOptionType {
        let description = '';

        if ('description' in dataModel && typeof dataModel.description === 'string') {
            description += `${dataModel.description} `;
        }

        return {
            label: description,
            value: dataModel.id,
        };
    }

    /*================================================================================================================
     *                                                  User
     * ==============================================================================================================*/

    public static fromUser = (user : IUser) : IOptionType => {
        return {
            label: user.name,
            value: user.id,
        };
    };

    /*================================================================================================================
     *                                                  Batch
     * ==============================================================================================================*/

    public static fromBatchToFullName = (batch : IBatch) : IOptionType => {
        return {
            label: `${batch.code} - ${batch.name}`,
            value: batch.id,
        };
    };
}