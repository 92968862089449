/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMutation } from '@tanstack/react-query';
import FreshServiceHttpService from '../../../service/http/freshService/freshServiceHttpService';
import { UPSERT_FRESH_SERVICE_TICKET } from '../queryConstants';
import { queryErrorHandler, queryNoReturnContentSuccessHandler } from '../queryStateHandlers';
import { IFreshServiceTicketUpsert } from '../../../@types/model/freshService/freshServiceTicketUpsert';

export const useHandleFreshServiceTicketUpsert = () => {
    const result = useMutation<void, Error, IFreshServiceTicketUpsert>({
        mutationKey: [UPSERT_FRESH_SERVICE_TICKET],
        mutationFn: (upsert : IFreshServiceTicketUpsert) => FreshServiceHttpService.upsert(upsert),
        onError: queryErrorHandler('Error creating Fresh Service Ticket.'),
        onSuccess: queryNoReturnContentSuccessHandler('Support Ticket created successfully.')
    });

    return result;
};