import axios from 'axios';
import { IGoogleLogInView } from '../../../@types/model/right/logIn/GoogleLogInView';
import { IPasswordReset } from '../../../@types/model/right/passwordReset/passwordResetModels';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { IUser } from '../../../@types/model/right/user/user';
import { IUserUpsert } from '../../../@types/model/right/user/userUpsert';

export default class UserHttpService {

    public static getList = async (signal ?: AbortSignal) : Promise<Array<IUser>> => {
        const result = await axios.get(`${API_URL}/v1/Right/User/GetList`, {
            signal
        });

        return result.data;
    }

    public static upsert = async (upsertList : Array<IUserUpsert>, type : UpsertTypeEnum) : Promise<Array<IUser>> => {
        const result = await axios.post(`${API_URL}/v1/Right/User/${type}`, upsertList);

        return result.data;
    }

    public static delete = async (userId : number) : Promise<void> => {
        const result = await axios.delete(`${API_URL}/v1/Right/User/Delete`, {
            params: { userId },
        });

        return result.data;
    }

    public static logoutUsers = async (userIds : Array<number>) : Promise<void> => {
        const result = await axios.post(`${API_URL}/v1/Right/User/LogoutUsers`, userIds);

        return result.data;
    }

    public static linkGoogleAccount = async (googleLogInView : IGoogleLogInView) : Promise<IUser> => {
        const result = await axios.post(`${API_URL}/v1/Right/User/LinkGoogleAccountToUser`, googleLogInView);

        return result.data;
    }

    public static unlinkGoogleAccount = async () : Promise<IUser> => {
        const result = await axios.post(`${API_URL}/v1/Right/User/UnlinkGoogleAccountFromUser`);

        return result.data;
    }

    public static requestForgottenPassword = async (emailOrEmployeeNumber : string) : Promise<void> => {
        const result = await axios.post(`${API_URL}/v1/Right/User/RequestPasswordReset`, null, {
            params: {
                emailOrEmployeeNumber,
            }
        });

        return result.data;
    }

    public static resetPassword = async (data : IPasswordReset) : Promise<void> => {
        const result = await axios.post(`${API_URL}/v1/Right/User/ResetUserPassword`, data);
        
        return result.data;
    }
}
