import axios from 'axios';
import { IFreshServiceTicketUpsert } from '../../../@types/model/freshService/freshServiceTicketUpsert';

export default class FreshServiceHttpService {
    public static upsert = async (upsert : IFreshServiceTicketUpsert) : Promise<void> => {
        const result = await axios.post(`${API_URL}/v1/FreshService/UpsertSupportTicket`, upsert);

        return result.data;
    };
}
