import axios from 'axios';
import { IGoogleLogInView } from '../../../@types/model/right/logIn/GoogleLogInView';
import { ILogInView } from '../../../@types/model/right/logIn/LogInView';
import { ISignUpView } from '../../../@types/model/right/logIn/SignUpView';
import { IUserToken } from '../../../@types/model/right/userToken/userToken';

export default class AuthHttpService {
    
    public static signUp = async (signUpView : ISignUpView) : Promise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v1/Authorisation/SignUp`, signUpView);

        return result.data;
    };

    public static logInManual = async (logInView : ILogInView) : Promise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v1/Authorisation/LogIn`, logInView);

        return result.data;
    }

    public static googleLogIn = async (googleLogInView : IGoogleLogInView) : Promise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v1/Authorisation/LogInGoogle`, googleLogInView);

        return result.data;
    }

    public static logout = async () : Promise<void> => {
        const result = await axios.get(`${API_URL}/v1/Authorisation/Logout`);

        return result.data;
    }
}
