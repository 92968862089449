import * as Yup from 'yup';
import { IPasswordResetFormValues } from './passwordResetModels';

export default class PasswordResetModelHelper {
    public static createFormValues = () : IPasswordResetFormValues => {
        return {
            password: '',
            confirmPassword: '',
        };
    };

    public static formSchema = () : Yup.ObjectSchema<IPasswordResetFormValues> => Yup.object({
        password: Yup.string().required('New password is required').min(8, 'Password must be at least 8 characters long.'),
        confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Confirming the new password is required.'),
    });
}