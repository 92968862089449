/*================================================================================================================
*                                                    Master Data
* ==============================================================================================================*/

// Activity Type
export const GET_ACTIVITY_TYPES_KEY = 'activityTypes';
export const UPSERT_ACTIVITY_TYPE_KEY = 'upsertActivityType';
export const DELETE_ACTIVITY_TYPE_KEY = 'deleteActivityType';

// Activity Type GROUP
export const GET_ACTIVITY_TYPE_GROUPS_KEY = 'activityTypeGroups';
export const UPSERT_ACTIVITY_TYPE_GROUP_KEY = 'upsertActivityTypeGroup';
export const DELETE_ACTIVITY_TYPE_GROUP_KEY = 'deleteActivityTypeGroup';

// Batch Status
export const GET_BATCH_STATUSES_KEY = 'batchStatuses';
export const UPSERT_BATCH_STATUS_KEY = 'upsertBatchStatus';
export const DELETE_BATCH_STATUS_KEY = 'deleteBatchStatus';

// Batch Type
export const GET_BATCH_TYPES_KEY = 'batchTypes';
export const UPSERT_BATCH_TYPE_KEY = 'upsertBatchType';
export const DELETE_BATCH_TYPE_KEY = 'deleteBatchType';

// Department
export const GET_DEPARTMENTS_KEY = 'departments';
export const UPSERT_DEPARTMENT_KEY = 'upsertDepartment';
export const DELETE_DEPARTMENT_KEY = 'deleteDepartment';

// Device
export const GET_DEVICES_KEY = 'devices';
export const UPSERT_DEVICE_KEY = 'upsertDevice';
export const DELETE_DEVICE_KEY = 'deleteDevice';

// Ingredient
export const GET_INGREDIENTS_KEY = 'ingredients';
export const UPSERT_INGREDIENT_KEY = 'upsertIngredient';
export const DELETE_INGREDIENT_KEY = 'deleteIngredient';

// Location
export const GET_LOCATIONS_KEY = 'locations';
export const UPSERT_LOCATION_KEY = 'upsertLocation';
export const DELETE_LOCATION_KEY = 'deleteLocation';

// Measurement Type
export const GET_MEASUREMENT_TYPES_KEY = 'measurementTypes';
export const UPSERT_MEASUREMENT_TYPE_KEY = 'upsertMeasurementType';
export const DELETE_MEASUREMENT_TYPE_KEY = 'deleteMeasurementType';

// Reason
export const GET_REASONS_KEY = 'reasons';
export const UPSERT_REASON_KEY = 'upsertReason';
export const DELETE_REASON_KEY = 'deleteReason';

// Recipe
export const GET_RECIPES_KEY = 'recipes';
export const UPSERT_RECIPE_KEY = 'upsertRecipe';
export const DELETE_RECIPE_KEY = 'deleteRecipe';

// Site
export const GET_SITES_KEY = 'sites';
export const UPSERT_SITE_KEY = 'upsertSite';
export const DELETE_SITE_KEY = 'deleteSite';

// Unit Of Measure
export const GET_UNITS_OF_MEASURE_KEY = 'unitsOfMeasure';
export const UPSERT_UNIT_OF_MEASURE_KEY = 'upsertUnitOfMeasure';
export const DELETE_UNIT_OF_MEASURE_KEY = 'deleteUnitOfMeasure';

// Raw Material Type
export const GET_RAW_MATERIAL_TYPES_KEY = 'rawMaterialTypes';
export const UPSERT_RAW_MATERIAL_TYPE_KEY = 'upsertRawMaterialType';
export const DELETE_RAW_MATERIAL_TYPE_KEY = 'deleteRawMaterialType';

/*================================================================================================================
*                                                  Activity
* ==============================================================================================================*/

export const GET_ACTIVITIES_KEY = 'activities';
export const UPSERT_ACTIVITY_KEY = 'upsertActivity';
export const DELETE_ACTIVITY_KEY = 'deleteActivity';

/*================================================================================================================
*                                                  Batch
* ==============================================================================================================*/

export const GET_BATCHES_KEY = 'batches';
export const UPSERT_BATCH_KEY = 'upsertBatch';
export const COMPLETE_BATCH_KEY = 'completeBatch';
export const DELETE_BATCH_KEY = 'deleteBatch';

/*================================================================================================================
*                                                  Measurement
* ==============================================================================================================*/

export const GET_MEASUREMENTS_KEY = 'measurements';
export const GET_LATEST_BATCH_MEASUREMENTS = 'latestBatchMeasurements';
export const GET_BATCH_LEVEL_MEASUREMENT_GRAPH_DATA = 'batchLevelMeasurementGraphData';
export const GET_AVERAGE_BATCH_MEASUREMENT_GRAPH_DATA_PER_HOUR = 'averageBatchMeasurementGraphDataPerHour';
export const GET_AVERAGE_BATCH_MEASUREMENT_GRAPH_DATA_PER_DAY = 'averageBatchMeasurementGraphDataPerDay';
export const UPSERT_MEASUREMENT_KEY = 'upsertMeasurement';
export const DELETE_MEASUREMENT_KEY = 'deleteMeasurement';

/*================================================================================================================
*                                                  Rights
* ==============================================================================================================*/

// users
export const GET_USER_KEY = 'user';
export const GET_USERS_KEY = 'users';
export const UPSERT_USERS_KEY = 'upsertUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_GOOGLE_ACCOUNT = 'unlinkUserGoogleAccount';
export const LOGOUT_USERS_KEY = 'logoutUsers';
export const DELETE_USER_KEY = 'deleteUser';
export const RESET_USER_PASSWORD_KEY = 'resetUserPassword';

// rights
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// roles
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';

/*================================================================================================================
*                                                Google Cloud Storage Service
* ==============================================================================================================*/

export const DOWNLOAD_GOOGLE_CLOUD_STORAGE_FILE_KEY = 'downloadGoogleCloudStorageFile';
export const UPLOAD_GOOGLE_CLOUD_STORAGE_FILE_KEY = 'uploadGoogleCloudStorageFile';
export const UPSERT_GOOGLE_CLOUD_STORAGE_FILE_KEY = 'upsertGoogleCloudStorageFile';
export const DELETE_GOOGLE_CLOUD_STORAGE_FILE_KEY = 'deleteGoogleCloudStorageFile';
export const GET_GOOGLE_CLOUD_STORAGE_LIST_DATA_KEY = 'getGoogleCloudStorageListData';

/*================================================================================================================
*                                                    Fresh Service
* ==============================================================================================================*/

export const UPSERT_FRESH_SERVICE_TICKET = 'upsertFreshServiceTicket';

/*================================================================================================================
*                                             Dev Tools Master Data
* ==============================================================================================================*/

// Logging
export const GET_LOGS_KEY = 'logs';