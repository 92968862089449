import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from './@types/redux';
import * as localStorageService from './service/localStorageService';
import './style/app.scss';
import { initializeInterceptor } from './service/http';
import AuthActions from './store/auth/actions';
import { useEffect, useMemo } from 'react';
import GeneralThunks from './store/general/thunks';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { IGoogleLoginError, IGoogleLoginSuccess, Loader, Login } from '@zz2/zz2-ui';
import NavFrame from './modules/root/NavFrame';
import { VERSION } from './version';
import AuthThunks from './store/auth/thunk';
import PasswordRecovery from './modules/root/PasswordRecovery';
import { IUserToken } from './@types/model/right/userToken/userToken';
import DataActions from './store/masterData/actions';
import EnvHelper from './service/helper/envHelper';
import { navReplace } from './store/nav/actions';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import materialTheme from './style/materialTheme';
import { QUERY_DATA_STALE_TIME } from './appConstants';
import { IPasswordResetFormValues } from './@types/model/right/passwordReset/passwordResetModels';
import PasswordResetModelHelper from './@types/model/right/passwordReset/passwordResetModelHelper';
import { ILogInView } from './@types/model/right/logIn/LogInView';
import { ISignUpView } from './@types/model/right/logIn/SignUpView';
import { IGoogleLogInView } from './@types/model/right/logIn/GoogleLogInView';
import { useLocation } from 'react-router';

const App = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    
    const session : IUserToken | null = useAppSelector(x => x.auth.session);
    const isLoggingIn : boolean = useAppSelector(x => x.auth.isLoggingIn);
    const isNavDrawerOpen : boolean = useAppSelector(x => x.general.isNavDrawerOpen);
    const showRecoveryScreen = location.pathname === '/reset-password';

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    const ReactQueryDevtoolsProduction = React.lazy(() =>
        import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
            (d) => ({
                default: d.ReactQueryDevtools,
            }),
        ),
    );

    useEffect(() => {
        dispatch(AuthActions.setLoggingIn(true));

        localStorageService.onSessionChanged((user) => {
            if (user) { /* Logged In */
                initializeInterceptor(user.token, onUnauthenticated, onUnauthorized, onConnectionError);

                dispatch(AuthActions.setSession(user));
                dispatch(AuthActions.setToken(user.token));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setLoggingIn(false));
            } else { /* Logged Out or Not yet logged in */
                initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setLoggedIn(false));
                dispatch(AuthActions.setSession(null));
                dispatch(AuthActions.setLoggingIn(false));
            }
        });
        localStorageService.onUserDataChanged((userData) => {
            if (userData) {
                dispatch(DataActions.setSelectedUserSiteId(userData.selectedUserSiteId));
            } else {
                dispatch(DataActions.setSelectedUserSiteId(null));
            }
        });
    }, []);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const manualLogIn = async (username : string, password : string) : Promise<void> => {
        const logIn : ILogInView = {
            emailOrUsername: username,
            password,
        };
        dispatch(AuthThunks.manualLogIn({ logIn }));
    };

    const manualSignUp = async (employeeNumber : string, password : string) : Promise<void> => {
        const googleSignUp : ISignUpView = {
            employeeNumber,
            password, 
        };

        dispatch(AuthThunks.signUp({ googleSignUp }));
    };

    const requestForgottenPassword = async (emailOrEmployeeNumber : string) : Promise<void> => {
        await dispatch(AuthThunks.requestForgottenPassword({ emailOrEmployeeNumber: emailOrEmployeeNumber }));
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        const googleLogIn : IGoogleLogInView = {
            code: response.code,
        };
        await dispatch(AuthThunks.googleLogIn({googleLogIn: googleLogIn}));

        navReplace('/home');
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onUnauthenticated = (error : unknown) : void => {
        initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Unauthorized.',
            e: error,
        }));

        dispatch(AuthActions.logout());
        dispatch(DataActions.reset());
        localStorageService.setLocalStorageSession(null);
        navReplace('/login');
    };

    const onUnauthorized = (error : unknown) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Insufficient rights.',
            e: error,
        }));
    };

    const onConnectionError = () : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Connection error.',
        }));
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Login Error',
            e: response,
        }));
        dispatch(AuthActions.setLoggingIn(false));
    };

    /*================================================================================================================
     *                                                    Memos
     * ==============================================================================================================*/

    const getInitialPasswordFormValues = useMemo<IPasswordResetFormValues>(() => {
        return PasswordResetModelHelper.createFormValues();
    }, []);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const renderApp = useMemo<React.ReactElement>(() => {
        if (isLoggingIn) {
            return <Loader/>;
        }
        
        if (session) {
            return (
                <NavFrame />
            );
        }

        return showRecoveryScreen 
            ? <PasswordRecovery initialFormValues={getInitialPasswordFormValues}/> 
            : <Login
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                isLoading={isLoggingIn}
                isLoggingIn={isLoggingIn}
                manualLogIn={manualLogIn}
                enableSignUp
                manualSignUp={manualSignUp}
                requestForgottenPassword={requestForgottenPassword}
                onGoogleSignInSuccess={onGoogleLogInSuccess}
                onGoogleSignInFailure={onGoogleLogInFailure}
            />;
    }, [isLoggingIn, session, showRecoveryScreen, isNavDrawerOpen]);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { staleTime: QUERY_DATA_STALE_TIME },
            mutations: {}
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={materialTheme}>
                { renderApp }
                <SnackbarNotifier />
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <React.Suspense fallback={null}>
                {
                    EnvHelper.isDevelopmentQa() &&
                    <ReactQueryDevtoolsProduction />
                }  
            </React.Suspense>
        </QueryClientProvider>
    );
};

export default App;