import { combineReducers } from 'redux';
import { createHashHistory } from 'history';
import auth from './auth/reducer';
import general from './general/reducer';
import masterData from './masterData/reducer';
import freshService from './freshService/reducer';
import { configureStore } from '@reduxjs/toolkit';

export const hashedHistory = createHashHistory();

export const createRootReducer = combineReducers({
    auth,
    masterData,
    general,
    freshService,
});

export const store = configureStore({
    reducer: createRootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;