import { IOptionType } from '@zz2/zz2-ui';

export default class UserHelper {

    public static getUserSiteHeading = (
        userSelectedSite : IOptionType | null,
    ) : string => {
        return userSelectedSite ?
            userSelectedSite.label
            : 'NO SITE SELECTED';
    };
}