/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useMutation, useQuery } from '@tanstack/react-query';
import { IGoogleLogInView } from '../../../@types/model/right/logIn/GoogleLogInView';
import { IPasswordReset } from '../../../@types/model/right/passwordReset/passwordResetModels';
import UserHttpService from '../../../service/http/right/userHttpService';
import { DELETE_USER_KEY, GET_USERS_KEY, LINK_USER_GOOGLE_ACCOUNT, LOGOUT_USERS_KEY, RESET_USER_PASSWORD_KEY, UNLINK_USER_GOOGLE_ACCOUNT, UPSERT_USERS_KEY } from '../queryConstants';
import { queryDeleteSuccessHandler, queryErrorHandler, queryNoReturnContentSuccessHandler, querySuccessHandler, queryUpdateSessionSuccessHandler } from '../queryStateHandlers';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { IUser } from '../../../@types/model/right/user/user';
import { IUserUpsert } from '../../../@types/model/right/user/userUpsert';

export const useGetUsers = () => {
    const query = useQuery<Array<IUser>, Error>({
        queryKey: [GET_USERS_KEY],
        queryFn: ({ signal }) => UserHttpService.getList(signal),
        onError: queryErrorHandler('Error loading Users.'),
    });

    return query;
};

export const useHandleUsersUpsert = () => {
    const result = useMutation<Array<IUser>, Error, { upsertList : Array<IUserUpsert>; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_USERS_KEY],
        mutationFn: (args : { upsertList : Array<IUserUpsert>; type : UpsertTypeEnum }) => UserHttpService.upsert(args.upsertList, args.type),
        onError: queryErrorHandler('Error creating/updating User(s).'),
        onSuccess: querySuccessHandler([GET_USERS_KEY], 'User(s) created/updated successfully.')
    });

    return result;
};

export const useHandleLinkGoogleAccount = () => {
    const result = useMutation<IUser | null, Error, IGoogleLogInView>({
        mutationKey: [LINK_USER_GOOGLE_ACCOUNT],
        mutationFn: (googleLogin : IGoogleLogInView) => UserHttpService.linkGoogleAccount(googleLogin),
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account linked successfully.')
    });

    return result;
};

export const useHandleUnlinkGoogleAccount = () => {
    const result = useMutation<IUser, Error, void>({
        mutationKey: [UNLINK_USER_GOOGLE_ACCOUNT],
        mutationFn: UserHttpService.unlinkGoogleAccount,
        onError: queryErrorHandler('Error encountered while trying to link Google Account.'),
        onSuccess: queryUpdateSessionSuccessHandler('Google Account unlinked successfully.')
    });

    return result;
};

export const useHandleLogoutUsers = () => {
    const result = useMutation<void, Error, Array<number>>({
        mutationKey: [LOGOUT_USERS_KEY],
        mutationFn: (userIds : Array<number>) => UserHttpService.logoutUsers(userIds),
        onError: queryErrorHandler('Error creating/updating User.'),
        onSuccess: queryNoReturnContentSuccessHandler('User(s) logged out successfully.')
    });

    return result;
};

export const useHandleUserDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_USER_KEY],
        mutationFn: (userId : number) => UserHttpService.delete(userId),
        onError: queryErrorHandler('Error deactivating User.'),
        onSuccess: queryDeleteSuccessHandler([GET_USERS_KEY], 'User successfully deactivated.'),
    });

    return result;
};

export const useHandleResetPassword = () => {
    const query = useMutation<void, Error, IPasswordReset>({
        mutationKey: [RESET_USER_PASSWORD_KEY],
        mutationFn: (data : IPasswordReset) => UserHttpService.resetPassword(data),
        onError: queryErrorHandler('Error resetting password.'),
        onSuccess: queryNoReturnContentSuccessHandler('Password reset successful.')
    });

    return query;
};