const theme = {
    colors: {
        primaryPallet: {
            50: '#eefff5',
            100: '#d8ffeb',
            200: '#b4fed7',
            300: '#7afbb9',
            400: '#39ef92',
            500: '#0fd872',
            600: '#06b35c',
            700: '#098c4a',
            800: '#0d703f',
            900: '#0d5a35',
            950: '#00331b',
        },
        secondaryPallet: {
            50: '#feffe7',
            100: '#fcffc1',
            200: '#feff86',
            300: '#fff941',
            400: '#ffec0d',
            500: '#ffdd00',
            600: '#d1a300',
            700: '#a67502',
            800: '#895b0a',
            900: '#744a0f',
            950: '#442704',
        },
        greyPallet: {
            50: '#f8f8f8',
            100: '#eaeaea',
            200: '#dcdcdc',
            300: '#bdbdbd',
            400: '#989898',
            500: '#7c7c7c',
            600: '#656565',
            700: '#525252',
            800: '#464646',
            900: '#3d3d3d',
            950: '#292929',
        },
        orangePallet: {
            50: '#fffaec',
            100: '#fff4d3',
            200: '#ffe5a5',
            300: '#ffd16d',
            400: '#ffb132',
            500: '#ff970a',
            600: '#ff7f00',
            700: '#cc5c02',
            800: '#a1470b',
            900: '#823c0c',
            950: '#461c04',
        },
        redPallet: {
            50: '#fff1f2',
            100: '#ffe0e2',
            200: '#ffc7cb',
            300: '#ff9fa6',
            400: '#ff6873',
            500: '#fa3947',
            600: '#e81d2c',
            700: '#c3121f',
            800: '#a1131d',
            900: '#85171f',
            950: '#49060b',
        },

        white: '#FFFFFF',
        black: '#000000',
        blue: '#192bc2',
        orange: '#FF7F00',
        orangeFade: '#FFBF80',

        grey0: '#EAEAEA',
        grey1: '#E3E3E3',
        grey2: '#DBEFF3',
        grey3: '#A5ADAF',
        red: '#B92D00',

        accent: '#fddb00',
        accent2: '#054F87',
        accent3: '#27C062',
        accent4: '#79CBBC',
        accent5: '#FFB82C',

        material: {
            primary: {
                dark: '#0b4323',
                main: '#0d703f',
                light: '#098442',
                lightFade: '#99AEA0',
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: '#FFDD00',
                contrastText: '#0D703F',
                hover: '#b8ae1f',
            },
            text: {
                primary: '#424242',
                secondary: '#757575',
                disabled: '#969696',
            },
        },
        table: {
            header: {
                light: {
                    text: '#0d703f',
                    background: '#B9C9C0',
                },
                main: {
                    text: '#FFFFFF',
                    background: '#0d703f',
                },
            },
            background: '#FFFFFF',
            text: '#000000',
            row1: {
                default: '#EAEAEA',
                inactive: '#fad1d0',
                warning: '#f8cca3',
            },
            row2: {
                default: '#FFFFFF',
                inactive: '#ffc6c4',
                warning: '#fdb482',
            },
            footer: {
                background: '#FFFFFF',
            },
            cell: {
                background: '#FFFFFF',
            },
            icon: '#0D703F',
        },
    },
};

export default theme;

