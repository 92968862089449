import axios from 'axios';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { IRight } from '../../../@types/model/right/right/right';
import { IRightUpsert } from '../../../@types/model/right/right/rightUpsert';

export default class RightHttpService {

    public static getList = async (signal ?: AbortSignal) : Promise<Array<IRight>> => {
        const result = await axios.get(`${API_URL}/v1/Rights/Right/GetList`, {
            signal
        });

        return result.data;
    }

    public static upsert = async (rightUpsert : IRightUpsert, type : UpsertTypeEnum) : Promise<IRight> => {
        const result = await axios.post(`${API_URL}/v1/Rights/Right/${type}`, rightUpsert);

        return result.data;
    };

    public static delete = async (rightId : number) : Promise<void> => {
        const result = await axios.delete(`${API_URL}/v1/Rights/Right/Delete`, {
            params: { rightId },
        });

        return result.data;
    };
}
