import { createReducer } from '@reduxjs/toolkit';
import DataActions from './actions';

export interface IDataState {
    selectedUserSiteId : number | null;
}

const initialState = {
    selectedUserSiteId: null,
};

const dataReducer = createReducer<IDataState>(initialState, (builder) => {
    builder.addCase(DataActions.setSelectedUserSiteId, (state, action) => {
        state.selectedUserSiteId = action.payload;
    });
    builder.addCase(DataActions.reset, () => {
        return initialState;
    });
});

export default dataReducer;
