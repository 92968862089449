
class EnvHelper {
    public static isDevelopmentQa = () : boolean => {
        return ENV_NAME === 'qa';
    }

    public static getEnvName = () : string => {
        if (ENV_NAME === 'production') {
            return 'ZZ2 COMPOST';
        } else if (ENV_NAME === 'qa') {
            return 'ZZ2 COMPOST QA';
        } else if (ENV_NAME === 'dev') {
            return 'ZZ2 COMPOST DEV';
        } else {
            return 'ZZ2 COMPOST ENV NOT FOUND';
        }
    };
}

export default EnvHelper;