import * as Yup from 'yup';
import { FreshServiceStatusEnum } from '../../enum/freshServiceStatusEnum';
import { FreshServicePriorityEnum } from '../../enum/freshServicePriorityEnum';
import { IOptionType } from '@zz2/zz2-ui';
import { IUser } from '../right/user/user';
import { IFreshServiceTicketFormValues } from './freshServiceTickerFormValues';
import { IFreshServiceTicketUpsert } from './freshServiceTicketUpsert';

export default class FreshServiceTicketModelHelper {
    public static createFormValues = (
        systemName : string,
        systemVersion : string,
        location : string,
        name : string,
        email : string,
        description : string | null,
    ) : IFreshServiceTicketFormValues => {
        return {
            systemName: systemName,
            systemVersion: systemVersion,
            location: location,
            name: name,
            email: email,
            description: description ?? '',
            status: FreshServiceStatusEnum.Open,
            priority: FreshServicePriorityEnum.Low,
            referencedUser: null,
        };
    };

    public static createUpsert = (freshTicketFormValues : IFreshServiceTicketFormValues, users ?: Array<IUser>) : IFreshServiceTicketUpsert => {
        const referencedUser = users?.find(x => x.id === freshTicketFormValues.referencedUser?.value);
        let newDescription : string = freshTicketFormValues.description;

        if (referencedUser) {  
            // description string needs to be in html for fresh service to format ticket correctly
            newDescription += `<br> <br>
              Manager Name: ${referencedUser.name}<br>
              Employee Number: ${referencedUser.employeeNumber}<br>
              Email: ${referencedUser.email}<br>`;
        }

        return {
            systemName: freshTicketFormValues.systemName,
            systemVersion: freshTicketFormValues.systemVersion,
            location: freshTicketFormValues.location,
            name: freshTicketFormValues.name,
            email: freshTicketFormValues.email,
            description: newDescription,
            priority: freshTicketFormValues.priority,
            status: freshTicketFormValues.status
        };
    };

    // Validation schema used by formik. https://github.com/jquense/yup
    public static formSchema = () : Yup.ObjectSchema<IFreshServiceTicketFormValues> => Yup.object({
        systemName: Yup.string().required(),
        systemVersion: Yup.string().required(),
        location: Yup.string().required(),
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        description: Yup.string().required(),
        status: Yup.number().positive().required(),
        priority: Yup.number().positive().required(),
        referencedUser: Yup.mixed<IOptionType>().required('Please select the person that you report to.'),
    });
}
