/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useQuery, useMutation } from '@tanstack/react-query';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';
import { ISite, ISiteUpsert } from '../../../@types/model/masterData/site/site';
import SiteHttpService from '../../../service/http/masterData/siteHttpService';
import { GET_SITES_KEY, UPSERT_SITE_KEY, DELETE_SITE_KEY } from '../queryConstants';
import { queryErrorHandler, querySuccessHandler, queryDeleteSuccessHandler } from '../queryStateHandlers';

export const useGetSites = () => {
    const query = useQuery<Array<ISite>, Error>({
        queryKey: [GET_SITES_KEY],
        queryFn: ({ signal }) => SiteHttpService.getList(signal),
        onError: queryErrorHandler('Error loading Sites.'),
    });

    return query;
};

export const useHandleSiteUpsert = () => {
    const result = useMutation<ISite, Error, { upsert : ISiteUpsert; type : UpsertTypeEnum }>({
        mutationKey: [UPSERT_SITE_KEY],
        mutationFn: (args : { upsert : ISiteUpsert; type : UpsertTypeEnum }) =>
            SiteHttpService.upsert(args.upsert, args.type),
        onError: queryErrorHandler('Error creating/updating Site.'),
        onSuccess: querySuccessHandler([GET_SITES_KEY], 'Site updated successfully.'),
    });

    return result;
};

export const useHandleSiteDelete = () => {
    const result = useMutation<void, Error, number>({
        mutationKey: [DELETE_SITE_KEY],
        mutationFn: (siteId : number) => SiteHttpService.delete(siteId),
        onError: queryErrorHandler('Error deleting Site.'),
        onSuccess: queryDeleteSuccessHandler([GET_SITES_KEY], 'Site inactivated successfully.'),
    });

    return result;
};
