import { Loader, NoRightsScreen, Screen } from '@zz2/zz2-ui';
import * as React from 'react';
import { Suspense, useMemo } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import FreshServiceActions from '../../store/freshService/actions';

{/* Home */}
const Home = React.lazy(() => import('../home/Home'));

{/* Master Data */}
const ActivityTypeManager = React.lazy(() => import('../masterData/activityType/ActivityTypeManager'));
const ActivityTypeGroupManager = React.lazy(() => import('../masterData/activityTypeGroup/ActivityTypeGroupManager'));
const MasterDataNavigation = React.lazy(() => import('../masterData/MasterDataManager'));
const DeviceManager = React.lazy(() => import('../masterData/device/DeviceManager'));
const MeasurementTypeManager = React.lazy(() => import('../masterData/measurementType/MeasurementTypeManager'));
const RecipeManager = React.lazy(() => import('../masterData/recipe/RecipeManager'));
const ReasonManager = React.lazy(() => import('../masterData/reason/ReasonManager'));
const UnitOfMeasureManager = React.lazy(() => import('../masterData/unitOfMeasure/UnitOfMeasureManager'));
const SiteHierarchyManager = React.lazy(() => import('../masterData/site/SiteHierarchyManager'));
const SiteManager = React.lazy(() => import('../masterData/site/SiteManager'));
const DepartmentManager = React.lazy(() => import('../masterData/department/DepartmentManager'));
const LocationManager = React.lazy(() => import('../masterData/location/LocationManager'));
const BatchTypeManager = React.lazy(() => import('../masterData/batchType/BatchTypeManager'));
const IngredientManager = React.lazy(() => import('../masterData/ingredient/IngredientManager'));
const BatchStatusManager = React.lazy(() => import('../masterData/batchStatus/BatchStatusManager'));
const RawMaterialTypeManager = React.lazy(() => import('../masterData/rawMaterialType/RawMaterialTypeManager'));

{/* Devices */}
const DeviceMap = React.lazy(() => import('../deviceManagement/DeviceMap'));
const DeviceOverview = React.lazy(() => import('../deviceManagement/components/DeviceOverview'));

{/* Activities */}
const ActivityManager = React.lazy(() => import('../activityManagement/ActivityManager'));

{/* Batches */}
const BatchManager = React.lazy(() => import('../batch/batchManagement/BatchManager'));
const BatchOverviewScreen = React.lazy(() => import('../batch/batchOverview/BatchOverviewScreen'));
const BatchHistoryManagement = React.lazy(() => import('../batch/batchHistory/BatchHistoryManagement'));

{/* Rights */}
const RightManager = React.lazy(() => import('../rights/rightsManagement/RightsManager'));
const UserManager = React.lazy(() => import('../rights/userManagement/UserManager'));

{/* Reports */}
const Report = React.lazy(() => import('../reports/Report'));

{/* Development */}
const LogManager = React.lazy(() => import('../logging/LogManager'));

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const dispatch = useAppDispatch();
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    const openSupportTicketDialog = () : void => {
        dispatch(FreshServiceActions.setIsSupportTicketDialogOpen(true));
    };

    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (!session.user.rights.length) return false;

        const rights = session.user.rights;
        return rights.some((n) => {
            /* Allow all sub urls if using the wildcard, otherwise match the url exactly */
            if (n.url && n.url.includes('/*')) {
                return url.startsWith(n.url.split('/*')[0]);
            } else {
                const newUrl = url.split('/');
                if (newUrl[1] === 'batchManagement') {
                    return true;
                } else if (newUrl[1] === 'activityManagement') {
                    return true;
                } else if (newUrl[1] === 'deviceManagement') {
                    return true;
                } else if (newUrl[1] === 'batchHistoryManagement') {
                    return true;
                } else {
                    return n.url === url;
                }
            }
        });
    };

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const render = (routeProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) {
                return;
            }
            const Component = component;
            if (!hasMenuRight(routeProps.match.url)) {
                return <NoRightsScreen openSupportTicket={openSupportTicketDialog} />;
            }
            return <Component {...routeProps} />;
        }
        return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />;
    };

    return (<Route {...rest} render={render} />);
};

const Routes = () : JSX.Element => {
    const userRights = useAppSelector(x => x.auth.session?.user.rights) ?? [];

    const reportRoutes = useMemo(() => {
        return userRights
            .filter(x => x.url?.startsWith('/report/'))
            .map(x => <PrivateRoute key={x.url} path={`${x.url}/:id?`} component={Report} />);
    }, [userRights]);

    return (
        <Suspense fallback={<Screen><Loader/></Screen>}>
            <Switch>
                <Route
                    path={'/'} exact
                    render={() => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />
                <PrivateRoute exact path='/home' component={Home} />

                {/* Master Data */}
                <PrivateRoute exact path='/masterData' component={MasterDataNavigation} />
                <PrivateRoute exact path='/masterData/activityTypeGroup' component={ActivityTypeGroupManager} />
                <PrivateRoute exact path='/masterData/activityType' component={ActivityTypeManager} />
                <PrivateRoute exact path='/masterData/batchType' component={BatchTypeManager} />
                <PrivateRoute exact path='/masterData/batchStatus' component={BatchStatusManager} />
                <PrivateRoute exact path='/masterData/deviceManager' component={DeviceManager} />
                <PrivateRoute exact path='/masterData/siteHierarchy' component={SiteHierarchyManager} />
                <PrivateRoute exact path='/masterData/site' component={SiteManager} />
                <PrivateRoute exact path='/masterData/department' component={DepartmentManager} />
                <PrivateRoute exact path='/masterData/location' component={LocationManager} />
                <PrivateRoute exact path='/masterData/measurementType' component={MeasurementTypeManager} />
                <PrivateRoute exact path='/masterData/reason' component={ReasonManager} />
                <PrivateRoute exact path='/masterData/recipe' component={RecipeManager} />
                <PrivateRoute exact path='/masterData/unitOfMeasure' component={UnitOfMeasureManager} />
                <PrivateRoute exact path='/masterData/ingredient' component={IngredientManager} />
                <PrivateRoute exact path='/masterData/rawMaterialType' component={RawMaterialTypeManager} />

                {/* Batches */}
                <PrivateRoute exact path='/batchManagement/:batchId?' component={BatchManager} />
                <PrivateRoute exact path='/batchHistoryManagement/:batchId?' component={BatchHistoryManagement} />
                <PrivateRoute exact path='/batchOverviewManagement' component={BatchOverviewScreen} />

                {/* Activities */}
                <PrivateRoute exact path='/activityManagement/:activityId?' component={ActivityManager} />

                {/* Devices */}
                <PrivateRoute exact path='/deviceManagement' component={DeviceMap} />
                <PrivateRoute exact path='/deviceManagement/overview/:deviceId?' component={DeviceOverview} />

                {/* Rights */}
                <PrivateRoute exact path='/rights/rightManagement' component={RightManager} />
                <PrivateRoute exact path='/rights/userManagement' component={UserManager} />

                {/* Development */}
                <PrivateRoute exact path = '/development/tools' component={LogManager} />
                {reportRoutes}
            </Switch>
        </Suspense>
    );
};

export default Routes;