import { openDb, UpgradeDB } from 'idb';
import { IOptionType } from '@zz2/zz2-ui';
import { IUserData } from '../@types/model/right/user/userData';
import { IUserToken } from '../@types/model/right/userToken/userToken';

const SESSION_NAME = 'zz2-compost-session';
const SESSION_KEY = 'zz2-compost-session-token';
const USER_DATA_NAME = 'zz2-compost-user-data';
const USER_DATA_KEY = 'zz2-compost-user-data-key';

const SELECTED_USER_SITE_KEY = 'selected-user-site';

let sessionCallback : (userToken : IUserToken | null) => void;
let userDataCallback : (userData : IUserData | null) => void;

export async function getLocalStorageSession() {
    let session : IUserToken | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        session = await getSessionIndexedDB();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        session = getSessionLocalStorage();
    }

    if (session) return session;

    return null;
}

export async function getLocalStorageUserData() : Promise<IUserData | null> {
    let userData : IUserData | null = null;
    let session : IUserToken | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        userData = await getUserDataIndexedDB();
        session = await getSessionIndexedDB();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        userData = getUserDataLocalStorage();
        session = getSessionLocalStorage();
    }

    if (userData?.userId === session?.userId) return userData;

    return null;
}

export async function setLocalStorageSession(userToken : IUserToken | null) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        await setSessionIndexedDB(userToken);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        setSessionLocalStorage(userToken);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sessionCallback) {
        sessionCallback(userToken);
    }
}

export async function setLocalStorageUserData(userData : IUserData | null) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        await setUserDataIndexedDB(userData);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        setUserDataLocalStorage(userData);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (userDataCallback) {
        userDataCallback(userData);
    }
}

function setSessionLocalStorage(userToken : IUserToken | null) : void {
    if (userToken) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(userToken));
    } else {
        localStorage.removeItem(SESSION_KEY);
    }
}

function setUserDataLocalStorage(userData : IUserData | null) : void {
    if (userData) {
        localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    } else {
        localStorage.removeItem(USER_DATA_KEY);
    }
}

function getSessionLocalStorage() : IUserToken | null {
    const session = localStorage.getItem(SESSION_KEY);

    if (session) return JSON.parse(session);

    return null;
}

function getUserDataLocalStorage() : IUserData | null {
    const userData = localStorage.getItem(USER_DATA_KEY);

    if (userData) return JSON.parse(userData);

    return null;
}

/**
 * Creates all object stores up to the current DB version. i.e. for version 2, this function will execute for versions
 * 0, 1 and 2.
 * @param db
 */
function upgradeDb(db : UpgradeDB) : void {
    switch (db.oldVersion) {
        case 0:
            if (!db.objectStoreNames.contains(SESSION_NAME)) {
                db.createObjectStore<IUserToken, string>(SESSION_NAME);
            }
            if (!db.objectStoreNames.contains(USER_DATA_NAME)) {
                db.createObjectStore<IUserData, string>(USER_DATA_NAME);
            }
    }
}

/**
 * Sets the auth session. If no session is specified, deletes the existing entry.
 * @param userToken The session.
 */
async function setSessionIndexedDB(userToken : IUserToken | null) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readwrite');

    const store = tx.objectStore(SESSION_NAME);

    await store.delete(SESSION_KEY);
    if (userToken) {
        await store.add(userToken, SESSION_KEY);
    }
    await tx.complete;
}

/**
 * Sets the user data. If no user data is specified, deletes the existing entry.
 * @param userData The user data.
 */
async function setUserDataIndexedDB(userData : IUserData | null) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(USER_DATA_NAME, 'readwrite');

    const store = tx.objectStore(USER_DATA_NAME);

    await store.delete(USER_DATA_KEY);
    if (userData) {
        await store.add(userData, USER_DATA_KEY);
    }
    await tx.complete;
}

/**
 * Opens the DB and retrieves the current auth session.
 */
async function getSessionIndexedDB() : Promise<IUserToken> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readonly');

    const result = tx.objectStore<IUserToken>(SESSION_NAME).get(SESSION_KEY);

    await tx.complete;

    return result;
}

/**
 * Opens the DB and retrieves the current user data.
 */
async function getUserDataIndexedDB() : Promise<IUserData> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(USER_DATA_NAME, 'readonly');

    const result = tx.objectStore<IUserData>(USER_DATA_NAME).get(USER_DATA_KEY);

    await tx.complete;

    return result;
}

/**
 * Specifies the callback that will be fired whenever the auth session undergoes a change.
 * @param callback
 */
export async function onSessionChanged(callback : (userToken : IUserToken | null) => void) : Promise<void> {
    sessionCallback = callback;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        indexedDBSessionChange();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        const session = getSessionLocalStorage();
        sessionCallback(session);
    }
}

/**
 * Specifies the callback that will be fired whenever the user data undergoes a change.
 * @param callback
 */
export async function onUserDataChanged(callback : (userData : IUserData | null) => void) : Promise<void> {
    userDataCallback = callback;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        indexedDBUserDataChange();
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        const userData = getUserDataLocalStorage();
        userDataCallback(userData);
    }
}

/**
 * Retrieves auth session, and once done fires the session callback.
 */
function indexedDBSessionChange() : void {
    getSessionIndexedDB().then((res) => {
        sessionCallback(res);
    }, () => {
        sessionCallback(null);
    });
}

/**
 * Retrieves user data, and once done fires the user data callback.
 */
function indexedDBUserDataChange() : void {
    getUserDataIndexedDB().then((res) => {
        userDataCallback(res);
    }, () => {
        userDataCallback(null);
    });
}

///////////////////

/**
 * Stores User Selected Site Id
 */
export function setUserSelectedSiteLocalStorage(siteId : IOptionType | null) : void {
    if (siteId) {
        localStorage.setItem(SELECTED_USER_SITE_KEY, JSON.stringify(siteId));
    } else {
        localStorage.removeItem(SELECTED_USER_SITE_KEY);
    }
}
/**
 * Retrieves User Selected Site Id
 */
export function getUserSelectedSiteLocalStorage() : IOptionType | null {
    const siteId = localStorage.getItem(SELECTED_USER_SITE_KEY);

    if (siteId) return JSON.parse(siteId);

    return null;
}