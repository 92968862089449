import axios from 'axios';
import { ISite, ISiteUpsert } from '../../../@types/model/masterData/site/site';
import { UpsertTypeEnum } from '../../../@types/enum/upsertType';

export default class SiteHttpService {

    public static getList = async (signal ?: AbortSignal) : Promise<Array<ISite>> => {
        const result = await axios.get(`${API_URL}/v1/MasterData/Site/GetList`, {
            signal
        });

        return result.data;
    }

    public static upsert = async (siteUpsert : ISiteUpsert, type : UpsertTypeEnum) : Promise<ISite> => {
        const result = await axios.post(`${API_URL}/v1/MasterData/Site/${type}`, siteUpsert);

        return result.data;
    };

    public static delete = async (siteId : number) : Promise<void> => {
        const result = await axios.delete(`${API_URL}/v1/MasterData/Site/Delete`, {
            params: { siteId },
        });

        return result.data;
    };
}