import { IconButton, Typography } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfirmationDialog, CustomTooltip, GoogleSignInButton, IGoogleLoginError, IGoogleLoginSuccess, IOptionType, MuiAutocompleteSelect, PillButton, ProjectDialog } from '@zz2/zz2-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { OptionType } from '../../@types/model/optionType';
import { IUser } from '../../@types/model/right/user/user';
import { useAppDispatch } from '../../@types/redux';
import { useGetSites } from '../../hooks/query/masterData/siteQueries';
import { useHandleLinkGoogleAccount, useHandleUnlinkGoogleAccount } from '../../hooks/query/user/userQueries';
import { getUserSelectedSiteLocalStorage, setLocalStorageUserData, setUserSelectedSiteLocalStorage } from '../../service/localStorageService';
import GeneralThunks from '../../store/general/thunks';
import DataActions from '../../store/masterData/actions';
import { IUserData } from '../../@types/model/right/user/userData';

interface IUserSettingsProps {
    isOpen : boolean;
    setIsUserDialogOpenCallback : (state : boolean) => void;
    currentUser : IUser | null;
    onClose : () => void;
    signOut : () => void;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch();

    const selectedSite = getUserSelectedSiteLocalStorage();

    const [selectedSiteOption, setSelectedSiteOption] = useState<IOptionType | null>(null);
    const [isUnlinkGoogleAccountDialogOpen, setIsUnlinkGoogleAccountDialogOpen] = useState<boolean>(false);
    const [isDiscardChangesDialogOpen, setIsDiscardChangesDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Queries
     * ==============================================================================================================*/

    const { isLoading: isLoadingSites, data: sites } = useGetSites();
    const { isLoading: isUnlinkingGoogleAccount, mutate: unlinkGoogleAccount } = useHandleUnlinkGoogleAccount();
    const { isLoading: isLinkingGoogleAccount, mutate: linkGoogleAccount } = useHandleLinkGoogleAccount();

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (selectedSite) {
            setSelectedSiteOption(selectedSite);
            
            const userData : IUserData = {
                selectedUserSiteId: Number(selectedSite.value),
                userId: props.currentUser?.id ?? 0,
            };

            setLocalStorageUserData(userData);
        }
    }, [props.isOpen]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onGoogleAccountLinkSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        linkGoogleAccount({ code: response.code });
    };

    const onGoogleAccountLinkFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Google Account linking failed.',
            e: response,
        }));
    };

    const onSiteChange = (selectedSite : IOptionType | null) : void => {
        setSelectedSiteOption(selectedSite);
    };

    const onCloseUserSettings = () : void => {
        if (!selectedSiteOption) {
            dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'Select a site.', e: {} }));
            return;
        }

        if (didSiteChange) {
            setIsDiscardChangesDialogOpen(true);
        } else {
            props.onClose();
        }
    };

    const onSaveUserSettings = () : void => {
        if (!selectedSiteOption)
            return;

        setUserSelectedSiteLocalStorage(selectedSiteOption);
        dispatch(DataActions.setSelectedUserSiteId(Number(selectedSiteOption.value)));
        props.onClose();
    };

    const openUnlinkEmailDialog = () : void => setIsUnlinkGoogleAccountDialogOpen(true);

    const closeUnlinkGoogleAccountDialog = () : void => setIsUnlinkGoogleAccountDialogOpen(false);

    const onUnlinkGoogleAccount = () : void => {
        if (!props.currentUser)
            return;

        unlinkGoogleAccount();
        closeUnlinkGoogleAccountDialog();
    };

    const closeDiscardDialog = () : void => {
        setIsDiscardChangesDialogOpen(false);
    };

    const onDiscardChanges = () : void => {
        setIsDiscardChangesDialogOpen(false);
        props.onClose();
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const isLoading = useMemo<boolean>(() => {
        return isLinkingGoogleAccount
        || isUnlinkingGoogleAccount
        || isLoadingSites;
    }, [
        isLinkingGoogleAccount,
        isUnlinkingGoogleAccount,
        isLoadingSites,
    ]);

    const siteOptions = useMemo<Array<IOptionType>>(() => {
        return sites?.filter(x => x.isActive).map(OptionType.fromDataModel) ?? [];
    }, [sites]);

    const didSiteChange = useMemo<boolean>(() => {
        return selectedSiteOption != null && selectedSite?.value !== selectedSiteOption.value;
    }, [selectedSite, selectedSiteOption]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <ProjectDialog
            title={'User Settings'}
            isOpen={props.isOpen}
            fullWidth
            maxWidth={'xs'}
            isLoadingCircular={isLoading}
            onClose={onCloseUserSettings}
            onConfirm={onSaveUserSettings}
            disableConfirm={!didSiteChange}
        >
            <div className={'fdc aic jcc m10'}>
                <MuiAutocompleteSelect
                    className={'w300 mt30'}
                    name={'site'}
                    label={'Site'}
                    options={siteOptions}
                    value={selectedSiteOption}
                    onChange={onSiteChange}
                />
                <div className={'h10'} />
                <div className={'fdc aic'}>
                    {
                        (!props.currentUser?.email || !props.currentUser.profilePictureURL)
                            && <div className={'zi1'}> 
                                <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
                                    <GoogleSignInButton          
                                        onGoogleSignInSuccess={onGoogleAccountLinkSuccess}
                                        onGoogleSignInFailure={onGoogleAccountLinkFailure}   
                                        buttonText={`Link ${(!!props.currentUser?.email && !props.currentUser.profilePictureURL) ? 'Profile Picture' : 'Google Account'}`}   
                                    />
                                </GoogleOAuthProvider>
                            </div>
                    }
                    {
                        !!props.currentUser?.email
                        && <div className={'fdc aic jcc'}>
                            <div className={'fdr aic jcc'}>
                                <Typography className={'fdr m10'}>Linked Email:</Typography>
                                <Typography variant={'body1'} className={'fdr fwb cpd m10'}>{ props.currentUser.email }</Typography>
                                <CustomTooltip title={'Unlink Email'}>
                                    <IconButton className={'cr'} onClick={openUnlinkEmailDialog}>
                                        <ClearIcon />
                                    </IconButton>
                                </CustomTooltip>
                            </div>
                        </div>
                    }
                </div>
                <div className={'h10'} />
                <div className={'fdr aic pb10'}>
                    <PillButton
                        className={'h35 w150'}
                        text={'LOGOUT'}
                        color={'secondary'}
                        onClick={props.signOut}
                    />
                </div>
            </div>
            <ConfirmationDialog 
                open={isUnlinkGoogleAccountDialogOpen}
                title={'Unlink Email'}
                description={'Are you sure you would like to unlink the email address currently associated with your account?'}
                onAccept={onUnlinkGoogleAccount}
                onClose={closeUnlinkGoogleAccountDialog}
                dialogType={'orange'}
            />
            <ConfirmationDialog
                title={'Discard Changes'}
                description={'You are about to close this form. Any changes made will be lost.'}
                open={isDiscardChangesDialogOpen}
                onAccept={onDiscardChanges}
                onClose={closeDiscardDialog}
                dialogType={'orange'}
            />
        </ProjectDialog>
    );
};

export default UserSettings;