import { AppBar, Card, CircularProgress, FormControl, Toolbar } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FormTextInput, Screen } from '@zz2/zz2-ui';
import { Form, FormikProps, withFormik } from 'formik';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PasswordResetModelHelper from '../../@types/model/right/passwordReset/passwordResetModelHelper';
import { IPasswordReset, IPasswordResetFormValues } from '../../@types/model/right/passwordReset/passwordResetModels';
import { VERSION } from '../../version';
import { useHandleResetPassword } from '../../hooks/query/user/userQueries';
import EnvHelper from '../../service/helper/envHelper';
import { navReplace } from '../../store/nav/actions';

interface IPasswordRecoveryProps {
    initialFormValues : IPasswordResetFormValues;
}

type PasswordRecoveryPropsType = IPasswordRecoveryProps & FormikProps<{ password : string; confirmPassword : string }>;

const PasswordRecovery = (props : PasswordRecoveryPropsType) : React.ReactElement => {
    const location = useLocation();
    const search = location.search;

    const { isLoading: isResettingPassword, mutate: resetPassword } = useHandleResetPassword();

    const changePasswordEnabled = useMemo<boolean>(() => {
        if (props.values.password != '' && props.values.confirmPassword != '') {
            return props.values.password == props.values.confirmPassword;
        }

        return false;
        
    }, [props.values]);
    
    const recoveryCode = search.split('recoveryCode=')[1]?.split('&')[0];
    const email = search.split('email=')[1];

    const submitPasswordRecoveryPasswordChange = () : void => {
        const newPassword = props.values.password;
        const passwordReset : IPasswordReset = {
            recoveryCode,
            email,
            newPassword
        };
        resetPassword(passwordReset);
        navReplace('/logIn');
    };

    return <Screen isPadded={false} >
        <AppBar position='static' className={'wsnw mxh52 h52 fdr aic'} elevation={0}>
            <img height={42} width={42} src={`${ASSET_BASE}/assets/icons/compost_logo.png`} className={'pl30'}/>
            <Typography color='inherit' variant='h6' className={'pl20'}>
                {EnvHelper.getEnvName()}
            </Typography>
            <span className={'flx1'} />
            <div className={'fdr jcfe aic'}>
                <sub className={'pt5 mr10 pr20'}>v{VERSION.version}</sub>
            </div>
        </AppBar>
        <div className={'flx1 fdr posr'}>
            <img src={`${ASSET_BASE}/assets/images/login_background.jpg`} className={'posa hfill wfill op70'} />
            {isResettingPassword &&
                <div className={'posa post0 posr0 posb0 posl0 aic jcc zi1000'}>
                    <div className={'posr aic jcc h50 w50'}>
                        <div className={'posa post0 posr0 posb0 posl0 aic jcc'}>
                            <img height={40} src={`${ASSET_BASE}/assets/icons/compost_logo.png`} />
                        </div>
                        <CircularProgress color={'primary'} className={'posa post0 posr0 posb0 posl0'} size={50} />
                    </div>
                </div>
            }
            {
                <Form className={'flx1 fdr aic jcc'}>
                    <Card className={'w500 fdc jcc zi2'}>
                        <Toolbar className={'bcpd h50'}>
                            <Typography variant='h6' className='flx1 cw jcc'>
                                Password Recovery
                            </Typography>
                        </Toolbar>
                        <div className={'pl20 pr20 pt10 pb10 pb10'}>
                            <FormControl className={'wfill'}>
                                <div className='fdc wfill'>
                                    <div className='fdc wfill'>
                                        <FormTextInput
                                            className={'flx1'}
                                            label={'Password'}
                                            name={'password'}
                                            type={'password'}
                                        />
                                    </div>
                                    <div className='fdc wfill'>
                                        <FormTextInput
                                            className={'flx1'}
                                            label={'Confirm Password'}
                                            name={'confirmPassword'}
                                            type={'password'}
                                        />
                                    </div>
                                </div>
                            </FormControl>
                            <div className={'fdr p5 pt20'}>
                                <Button
                                    className={'flx1 br25'}
                                    variant='contained'
                                    color='secondary'
                                    onClick={submitPasswordRecoveryPasswordChange}
                                    disabled={isResettingPassword || !changePasswordEnabled}
                                >
                                    CHANGE PASSWORD
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Form>
            }
        </div>
    </Screen>;
};

export default withFormik<IPasswordRecoveryProps, IPasswordResetFormValues>({
    mapPropsToValues: props => props.initialFormValues,
    handleSubmit: () => null,
    enableReinitialize: true,
    validationSchema: PasswordResetModelHelper.formSchema,
})(PasswordRecovery);